import logoLink from '../resources/lovion.png';

export const cssVariables = {
    
    primary: '#222E83',
    primary_light: '#FFC299',
    primary_dark: '#FF6600',
    primary_transparent: '#cccccc',
    text_on_primary: 'white',
    
    secondary: '#333333',
    secondary_light: '#7e7e7e',
    secondary_transparent: '#f2f2f2',
    text_on_secondary: 'white',
    
    font: 'Arial, Helvetica, sans-serif',
    link_color: '#FF8C00',
    font_color: '#000',

    active: '#8cc800',
    inactive: '#cccccc',

    edit_color: '#222E83',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#333333',

    dropdown_background: '#FFC299',
    text_on_dropdown: '#000',
};

export const logo = logoLink;
